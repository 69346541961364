export default function translate(value) {
    const traduction = {
        "warehouse"                  : "entrepôt",
        "truck"                      : "camion",
        "cell"                       : "cellule",
        "node"                       : "node",
        "helper"                     : "autre",
        "delivery"                   : "livraison",
        "customer_storage"           : "client",
        "new"                        : "Nouveau",
        "active"                     : "Actif",
        "Ready"                      : "Prête à être réceptionnée",
        "available"                  : "Disponible",
        "Preparation"                : "En préparation",
        "Completed"                  : "Complétée",
        "Canceled"                   : "Annulée",
        "returning"                  : "En retour",
        "refurbished"                : "Recertifié",
        "outdated"                   : "Désuet",
        "in_use"                     : "En utilisation",
        "broken"                     : "Brisé",
        "antenna"                    : "Antenne",
        "router"                     : "Routeur",
        "commutator"                 : "Switch",
        "optical_network_termination": "ONT",
        "optical_line_terminal"      : "OLT",
        "battery"                    : "Batterie",
        "case"                       : "Boitier",
        "analog_telephone_adapter"   : "ATA",
        "set_top_box"                : "Décodeur TV",
        "tv_remote"                  : "Manette TV",
        "power_supply"               : "Bloc d'alimentation",
        "cable"                      : "Câblage",
        "hardware"                   : "Quincaillerie",
        "warehousePanel"             : "Entrepôt",
        "truckPanel"                 : "Camion",
        "adminPanel"                 : "Administrateur",
        "contractorPanel"            : "Sous-traitant",
        "adapter"                    : "Adaptateur",
        "move"                       : "déplacé",
        "lost"                       : "perdu",
        "found"                      : "retrouvé",
        "create"                     : "créé",
        "labeled"                    : "étiqueté",
        "rented"                     : "loué",
        "sold"                       : "vendu",
        "takenBack"                  : "récupéré",
        "expediboxDepositReservation": "Réservé",
        "expediboxDepositConfirmation": "Déplacé",
        "exit"                       : "sortie",
        "correction"                 : "corrigé",
        "installed"                  : "installé",
        "customer"                   : "Client",
        "unknown"                    : "Inconnu",
        "reserved"                   : "Réservé",
        "suspended"                  : "Suspendu",
        "interrupted"                : "Interrompu",
        "closed"                     : "Fermé",
        "directional"                : "Directionnel",
        "omni"                       : "Omnidirectionnel",
        "radio"                      : "Radio",
        "false"                      : "Non",
        "true"                       : "Oui",
        "fiber"                      : "Fibre",
        "wireless"                   : "Sans fil",
        "month"                      : "Mensuel",
        "year"                       : "Annuel",
        "Hdmi"                       : "HDMI",
        "waiting"                    : "À valider",
        "onHold"                     : "Suspendue",
        "awaitingCompletion"         : "En attente de conclusion",
        "done"                       : "Terminé",
        "sent"                       : "Envoyé",
        "cancelled"                  : "Annulé",
        "up"                         : "En ligne",
        "down"                       : "Hors ligne",
        "unavailable"                : "Indisponible",
        "discovering"                : "En découverte",
        "rejected"                   : "Refusé",
        "discovered"                 : "Découvert",
        "Not in call"                : "Accroché",
        "In call"                    : "Décroché",
        "repairsPanel"               : "Réparations",
        "repair"                     : "Réparation",
        "calldate"                   : "Date de l'appel",
        "src"                        : "Source",
        "dst"                        : "Destination",
        "billsec"                    : "Durée de l'appel",
        "inProgress"                 : "En cours",
        "en"                         : "Anglais",
        "fr"                         : "Français",
        "tenBaseTFullDuplex"         : "10 FULL",
        "hundredBaseTFullDuplex"     : "100 FULL",
        "gigabitEthernetFullDuplex"  : "Gigabit FULL",
        "tenBaseTHalfDuplex"         : "10 HALF",
        "hundredBaseTHalfDuplex"     : "100 HALF",
        "gigabitEthernetHalfDuplex"  : "Gigabit HALF",
        'info': 'info',
        'alert': 'alerte',
        'minor': 'mineure',
        'major': 'majeure',
        'critical': 'critique',
    };

    if (traduction[value]) {
        return traduction[value];
    }

    return value;
}